<template>
    <div
        id="app"
        class="loading"
        @click="handlerGlobalClick"
    >
    <!-- 
        :style="{'background-image': `url(${this.bcColor})`}"
    -->
        <component :is="curCom"></component>
        <Guiding :routerInfo=$route @openFunctionality="openFunctionality"/>
    </div>
</template>

<script>
import Login from "@/views/Login.vue";
import autoJump from "@/views/AutoJump.vue";
import GroupLogin from "@/views/GroupLogin.vue";
import GroupSchool from "@/views/GroupSchool.vue";
import SetCenterMainContainer from "@/views/SetCenterMainContainer.vue"; // 设置中心主容器
import MainContainer from "@/views/MainContainer.vue";
import Empty from "@/views/Empty.vue"; // 空页面
import { router as setCenterRouter } from "@/assets/data/SetCenter/router.js" // 设置中心下的页面name

export default {
    name: "App",
    components: {
        Login,
        MainContainer,
    },
    data() {
        return {
            components: {
                login: Login,
                autoJump: autoJump,
                GroupLogin: GroupLogin,
                GroupSchool: GroupSchool,
                setCenterMainContainer: SetCenterMainContainer, // 设置中心主容器
                mainContainer: MainContainer,
                empty: Empty // 空页面
            },
            bcColor: null,
        };
    },
    computed: {
        curCom() {
            const routeMode = this.$route.name;
            if (!routeMode && setCenterRouter.includes(location.hash.replace('#/', ''))) {
                document.body.style.transition = 'none';
                return this.components["empty"]
            } else {
                document.body.style.transition = 'all ease 0.2s';
            };
            switch (routeMode) {
                case "login":
                case "GroupLogin":
                case "GroupSchool":
                case "autoJump":  
                    return this.components[routeMode];
                default:
                    if (setCenterRouter.includes(routeMode)) { // 检查是不是设置中心下的页面
                        return this.components["setCenterMainContainer"]
                    }
                    return this.components["mainContainer"];
            }
        },
    },
    mounted() {
        this.updateFavicon()
    },
    methods: {
        /**
         * @Description: 更新项目图标
         * @Author: 韩熙昊
         * @Date: 2024-09-04 05:42:54
         */
        async updateFavicon() {
            try {
                const logo = await this.getProjectDetails()
                if (logo) {
                    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
                    link.type = 'image/x-icon'
                    link.rel = 'shortcut icon'
                    link.href = logo
                    document.getElementsByTagName('head')[0].appendChild(link)
                }
            } catch (error) {
                console.error('更新图标失败:', error)
            }
        },
        /**
         * @Description: 获取项目详情
         * @Author: 韩熙昊
         * @Date: 2024-09-04 05:42:54
         */
        async getProjectDetails() {
            try {
                const res = await this._fet('/auth/sc/getDetailByProjectMark', {
                    productId: 'cloud_campus',
                    projectMark: this.$store.state.projectMark
                })
                if (res.data && res.data.code === '200') {
                    return res.data.data.logo
                } else {
                    throw new Error('请求数据异常')
                }
            } catch (error) {
                console.error('[getDetailByProjectMark]接口请求失败:', error)
                return null
            }
        },
        changeBodyBcImg(newVal) {
            switch (newVal.name) {
                case "FirstPage":
                case "firstPageHome":
                    // this.bcColor = backgroundImage;
                    break;
                case "login":
                    this.undo();
                default:
                    // this.bcColor = null;
                    break;
            }
        },
        undo () {
            this.closeFeedBack()
        },
        /**
         * @Description: 关闭反馈
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-21 12:06:22
         */
        closeFeedBack () {
            try {
                const oFeedback = document.querySelector('#J_feedback');
                document.body.removeChild(oFeedback);
            }catch(e){}
        },
        /**
         * @Description: 全局点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-17 15:45:32
         */
         handlerGlobalClick (e) {
            this.$eventBroadcast('handlerGlobalClick', e);
            this.undo();
         },
         handlerGlobalPageBack () {
            this.$eventBroadcast('handlerGlobalPageBack')
         },
         openFunctionality (type) {
            this.$eventBroadcast(type)
         }
    },
    watch: {
        $route: {
            handler(newVal) {
                this.changeBodyBcImg(newVal);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import url('./xkUI/pages/Guiding/src/css/index.scss');
#app {
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: var(--layout-bc-img)
}
.loading {
    background-color: #ebf2fa;
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        #ebf2fa;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}
</style>
